import axios from 'axios';
import BaseURL from './base-url';
/* eslint-disable */
let api = BaseURL;

const http = axios.create({
  baseURL: api
})

http.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

http.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    location.href = '/login';
  }
  if (error.response.status === 403) {
    location.href = '/';
  }

  return Promise.reject(error)
})

export default http
