<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section" v-if="hasData">
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <div>
        <v-row>
          <v-col cols="5" md="4">
            <v-text-field
              label="Data Inicial"
              v-model="startDate"
              type="date"
              @change="changeAll()"
            ></v-text-field>
          </v-col>
          <v-col cols="5" md="4" class="ml-8">
            <v-text-field
              label="Data Final"
              v-model="endDate"
              type="date"
              @change="changeAll()"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <base-material-stats-card
          color="#2196f3"
          icon="mdi-account-supervisor"
          title="Total de Membros"
          :value="countMembers ? countMembers.toString() : '0'"
          sub-icon="mdi-account"
          cardColor="#2196f3"
        />
      </v-col>
      <v-col cols="12" md="4">
        <base-material-stats-card
          color="#1B5E20"
          icon="mdi-account-plus"
          title="Conversões no Período"
          :value="countConversion ? countConversion.toString() : '0'"
          sub-icon="mdi-account-plus"
          cardColor="#1B5E20"
        />
      </v-col>
      <v-col cols="12" md="4">
        <base-material-stats-card
          color="rgb(59 167 162)"
          icon="mdi-account-check"
          title="Batismos no Período"
          :value="countBaptism ? countBaptism.toString() : '0'"
          sub-icon="mdi-account"
          cardColor="rgb(59 167 162)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <BarChart :chartData="chartData.credits" :options="options" />
            <v-card-actions>
              <v-card-subtitle
                class="overline"
                v-text="'Entradas Por Categoria'"
              />
            </v-card-actions>
          </v-card>
        </template>
      </v-col>

      <v-col cols="12" md="4">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <BarChart :chartData="chartData.debits" :options="options" />
            <v-card-actions>
              <v-card-subtitle
                class="overline"
                v-text="'Saídas Por Categoria'"
              />
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
      <v-col cols="12" md="4">
        <template>
          <v-card color="dark" v-if="hasData" class="dash-card">
            <PieChart :chartData="chartResults" :options="pieOptions" />
            <v-card-actions>
              <v-card-subtitle
                :class="
                  result.balance > 0
                    ? 'green overline text-center text-white'
                    : 'red overline text-center text-white'
                "
                style="width: 100% !important"
                v-text="'Saldo Previsto ' + formatPrice(result.balance)"
              />
            </v-card-actions>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, article"></v-skeleton-loader>
      </v-col>
      <v-col cols="12">
        <v-skeleton-loader type="article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import http from "@/services/http";
import moment from "moment";
import BarChart from "@/components/component/BarChart";
import PageHeader from "@/components/core/PageHeader.vue";
import PieChart from "@/components/component/PieChart";
import BaseMaterialStatsCard from "@/components/component/MaterialStatsCard.vue";
export default {
  name: "DashboardDashboard",
  components: { BarChart, PageHeader, PieChart, BaseMaterialStatsCard },
  data() {
    return {
      title: "Dashboard",
      startDate: moment().startOf("month").format("yyyy-MM-DD"),
      endDate: moment().endOf("month").format("yyyy-MM-DD"),
      menu: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Dahsboard",
          disabled: true,
          href: "/",
        },
      ],
      hasData: false,
      companies: "",
      countMembers: 0,
      countCells: 0,
      countConversion: 0,
      countBaptism: 0,
      countInactiveMembers: 0,
      totalDebits: 0.0,
      totalCredits: 0.0,
      balance: 0.0,
      movements: [],
      chartMonth: [],
      chartData: {},
      chartResults: {},
      result: {
        payable: 0,
        receivable: 0,
        balance: 0,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: true,
                min: 0,
              },
              gridLines: {
                display: true,
                drawBorder: false,
              },
            },
          ],
        },
      },
      pieOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                display: false,
                min: 0,
              },
              gridLines: {
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      },

      tabs: 0,
    };
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatPrice(value, type) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getMovements() {
      http
        .get("finance/transactions?limit=10&sort=dueDate,DESC")
        .then((data) => {
          this.movements = data.data;
        });
    },

    selectMoth(month) {
      switch (month) {
        case 1:
          return "Janeiro R$";
          break;
        case 2:
          return "Fevereiro R$";
          break;
        case 3:
          return "Março R$";
          break;
        case 4:
          return "Abril R$";
          break;
        case 11:
          return "Novembro R$";
          break;
        case 12:
          return "Dezembro R$";
          break;
        default:
          break;
      }
    },
    async fetchResults() {
      this.hasData = false;
      await http
        .get(`results?startDate=${this.startDate}&endDate=${this.endDate}`)
        .then(
          async (data) => {
            this.result.payable = (await data?.data?.debits?.amount) || 0;
            this.result.receivable = (await data?.data?.credits?.amount) || 0;
            this.result.sale = (await data?.data?.sale) || {
              amount: 0,
              amountPaid: 0,
              amountPay: 0,
            };
            this.result.balance = (
              this.result.receivable - this.result.payable
            ).toFixed(2);
            this.chartResults = await {
              labels: ["Receber", "Pagar"],
              datasets: [
                {
                  label: "R$",
                  backgroundColor: [
                    "#008080",
                    "#8B0000",
                    "#20B2AA",
                    "#48D1CC",
                    "#40E0D0",
                    "#00CED1",
                    "#5F9EA0",
                    "#00FFFF",
                    "#2F4F4F",
                    "#8FBC8F",
                    "#5F9EA0",
                    "#20B2AA",
                  ],
                  data: [this.result.receivable, this.result.payable],
                },
              ],
            };
            this.hasData = true;
          },
          () => (this.hasData = true)
        );
    },
    async fetchMovementsYear() {
      this.hasData = false;
      await http
        .get(
          `results/movements?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then(
          async (data) => {
            this.movements = await data.data.movements;
            this.chartData.credits = await {
              labels: data.data.credits.map((d) => d.name),
              datasets: [
                {
                  label: "Entradas R$",
                  backgroundColor: [
                    "#008080",
                    "#5F9EA0",
                    "#20B2AA",
                    "#48D1CC",
                    "#40E0D0",
                    "#00CED1",
                    "#5F9EA0",
                    "#00FFFF",
                    "#2F4F4F",
                    "#8FBC8F",
                    "#5F9EA0",
                    "#20B2AA",
                  ],
                  data: data.data.credits.map((d) => d.amount),
                },
              ],
            };
            this.chartData.debits = await {
              labels: data.data.debits.map((d) => d.name),
              datasets: [
                {
                  label: "Saídas R$",
                  backgroundColor: [
                    "#800000",
                    "#8B0000",
                    "#B22222",
                    "#A52A2A",
                    "#FF0000",
                    "#A52A2A",
                    "#800000",
                    "#B22222",
                    "#FF4500",
                    "#DC143C",
                    "#B22222",
                    "#FF0000",
                  ],
                  data: data.data.debits.map((d) => d.amount),
                },
              ],
            };
            this.hasData = true;
          },
          () => (this.hasData = true)
        );
    },
    async getCountCards() {
      http
        .get(
          `/promise/dashboard/count-members?startDate=${this.startDate}&endDate=${this.endDate}`
        )
        .then((res) => {
          const { data } = res;
          this.countMembers = data.countActiveMembers;
          this.countCells = data.countCells;
          this.countBaptism = data.countBaptism;
          this.countConversion = data.countConversion;
        });
    },
    async checkPermission() {
      let user = localStorage.getItem("user");
      if (user) user = JSON.parse(user);
      if (!user) this.$router.push("/login");
      if (user.admin) return;
      const permissions = user?.group?.permissions?.map((d) => d.id);

      if (permissions.includes("gerentor.dashboard")) return;
      else if (permissions.includes("promise.dashboard")) return;
      else if (permissions.includes("promise.cells.get_many")) {
        this.$router.push("/promise/cells");
      } else if (permissions.includes("gerentor.person.customers.get_many")) {
        this.$router.push("/members");
      } else if (permissions.includes("promise.events.get_many")) {
        this.$router.push("/events");
      } else {
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    async changeAll() {
      await this.checkPermission();
      await this.getCountCards();
      await this.fetchResults();
      await this.fetchMovementsYear();
    },
  },
  async mounted() {
    await this.changeAll();
  },
};
</script>
<style scoped>
.text-white {
  color: white !important;
}
.dash-card {
  padding-top: 20px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 10px !important;
}
</style>