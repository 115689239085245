import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/accounting/documents',
    name: 'Documentos',
    component: () => import('@/components/tributes/DocumentType.vue'),
  },

  {
    path: '/finance/categories',
    name: 'Classificações Financeiras',
    component: () => import('@/components/finance/FinancialCategory.vue'),
  },
  {
    path: '/finance/account-reports',
    name: 'Extrato de Conta',
    component: () => import('@/components/finance/AccountReport.vue'),
  },
  {
    path: '/customers/birthdays',
    name: 'Aniversariantes',
    component: () => import('@/components/base/CustomerBirth.vue'),
  },
  {
    path: '/finance/accounts',
    name: 'Contas Financeiras',
    component: () => import('@/components/finance/FinancialAccount.vue'),
  },
  {
    path: '/finance/movements',
    name: 'Lançamentos Financeiros',
    component: () => import('@/components/finance/FinancialMovement.vue'),
  },
  {
    path: '/finance/movement/instalments',
    name: 'Parcelamentos',
    component: () => import('@/components/finance/FinancialMovementInstalment.vue'),
  },
  {
    path: '/finance/account-payables',
    name: 'Contas a Pagar',
    component: () => import('@/components/finance/AccountPayable.vue'),
  },
  {
    path: '/finance/report/account-receivables',
    name: 'Relatório Contas a Receber',
    component: () => import('@/components/finance/AccountReceivableReport.vue'),
  },
  {
    path: '/finance/report/account-payables',
    name: 'Relatório Contas a Pagar',
    component: () => import('@/components/finance/AccountPayableReport.vue'),
  },
  {
    path: '/finance/payment-methods',
    name: 'Métodos de Pagamento',
    component: () => import('@/components/finance/PaymentMethod.vue'),
  },
  {
    path: '/finance/transaction/create',
    name: 'Nova Transação Financeira',
    component: () => import('@/components/finance/FinancialTransactionForm.vue'),
  },

  /* base */
  {
    path: '/members',
    name: 'Membros',
    component: () => import('@/components/base/Customer.vue'),
  },
  {
    path: '/base/suppliers',
    name: 'Fornecedores',
    component: () => import('@/components/base/Supplier.vue'),
  },
  {
    path: '/promise/cells',
    name: 'Pedidos Em Andamento',
    component: () => import('@/components/ministery/Cell.vue'),
  },
  {
    path: '/members/register',
    name: 'Cadastro de Membros',
    component: () => import('@/views/MemberForm.vue'),
  },
  {
    path: '/members/:id/edit',
    name: 'Edição de Membros',
    component: () => import('@/views/MemberForm.vue'),
  },

  {
    path: '/base/employees',
    name: 'Funcionários',
    component: () => import('@/components/immobile/Employee.vue'),
  },
  {
    path: '/shepherds',
    name: 'Pastores',
    component: () => import('@/components/base/Shepherd.vue'),
  },
  {
    path: '/events',
    name: 'Eventos',
    component: () => import('@/views/Event.vue'),
  },
  {
    path: '/events/register',
    name: 'Criar Eventos',
    component: () => import('@/views/EventForm.vue'),
  },
  {
    path: '/events/:id/edit',
    name: 'Editar Eventos',
    component: () => import('@/views/EventForm.vue'),
  },
  {
    path: '/groups/:groupId/members',
    name: 'Membros do Grupo',
    component: () => import('@/views/GroupMembers.vue'),
  },
  {
    path: '/events/:eventId/participants',
    name: 'Participantes do Evento',
    component: () => import('@/views/Participants.vue'),
  },

  {
    path: '/promise/networks',
    name: 'Redes',
    component: () => import('@/views/Networks.vue'),
  },
  {
    path: '/promise/ministry',
    name: 'Redes',
    component: () => import('@/views/Ministry.vue'),
  },
  {
    path: '/promise/ministry/:ministryId/persons',
    name: 'Membros Ministério',
    component: () => import('@/views/MinistryPerson.vue'),
  },
  {
    path: '/patrimony/create',
    name: 'Registrar Patrimonio',
    component: () => import('@/components/base/ProductForm.vue'),
  },
  {
    path: '/patrimony',
    name: 'Patrimonio',
    component: () => import('@/components/base/Product.vue'),
  },
  {
    path: '/patrimony/:id/edit',
    name: 'Editar Patrimonio',
    component: () => import('@/components/base/ProductForm.vue'),
  },
  {
    path: '/patrimony/categories',
    name: 'Classificação de Patrimonio',
    component: () => import('@/components/products/Categories.vue'),
  },
  {
    path: '/patrimony/transactions',
    name: 'Movimentação de Patrimonio',
    component: () => import('@/components/base/StockTransactions.vue'),
  },
  {
    path: '/patrimony/transactions/create',
    name: 'Cadastro Movimentação de Patrimonio',
    component: () => import('@/components/base/StockTransactionForm.vue'),
  },
  {
    path: '/patrimony/transactions/:id/edit',
    name: 'Edição Movimentação de Patrimonio',
    component: () => import('@/components/base/StockTransactionForm.vue'),
  },

];

const router = new VueRouter({
  mode: !process.env.IS_ELECTRON ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
